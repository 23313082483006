<template>
    <!-- <v-container fluid class="pa-16"> -->
    <!-- <div class="red wrapper"> -->
    <v-container fluid class="wrapper pa-8">
        <div
            v-if="loading"
            class="d-flex justify-center align-center fill-height"
        >
            <v-progress-circular
                color="primary"
                size="75"
                indeterminate
            ></v-progress-circular>
        </div>
        <div
            v-if="error"
            class="d-flex justify-center align-center fill-height"
        >
            <v-alert type="error">
                {{ error }}
            </v-alert>
        </div>

        <v-row
            v-if="loaded && !loading"
            class="d-flex align-content-start farm-container"
            justify="center"
        >
            <v-col
                v-for="farm in farms"
                :key="`${farm.IDConsorzio}`"
                class="d-flex justify-center"
                xs="6"
                sm="6"
                md="4"
                lg="3"
                xl="2"
            >
                <FarmCard
                    :imgSource="getFarmIcon(farm.IDConsorzio)"
                    :title="farm.Consorzio"
                    :count="farm.NumeroClientiDelConsorzio"
                    @selected="selectFarm(farm)"
                >
                </FarmCard>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-fab-transition v-if="farms.length > 3">
            <v-btn
                color="pink"
                dark
                fab
                class="v-btn-add flex-item-bottom"
                @click="multiAction"
            >
                <v-icon>mdi-file-multiple</v-icon>
            </v-btn>
        </v-fab-transition>
    </v-container>
    <!-- </div> -->
</template>

<script>
import { getFarmIcon } from "../../mixins/utils";
import { mapActions, mapMutations, mapState } from "vuex";
import * as farmAction from "../../store/actions/farm";
import * as filterActions from "../../store/actions/filter";
import * as storeActions from "../../store/actions/store";
import * as clusterActions from "../../store/actions/cluster";
import * as promoActions from "../../store/actions/promo";
import * as snackbarAction from "../../store/actions/snackbar";
import * as multiFarmPromoAction from "../../store/actions/multiFarmPromo";

export const FarmCard = () => import("../../components/core/AppFarm");

export default {
    components: {
        FarmCard
    },

    data: () => ({}),

    mixins: [getFarmIcon],

    computed: {
        ...mapState({
            farms: state => state.farmModule.farms,
            multiFarmPromos: state => state.promoMultiFarmModule.promos,
            loading: state => state.farmModule.loading,
            loaded: state => state.farmModule.loaded,
            error: state => state.farmModule.error
        }),
    },

    methods: {
        ...mapActions({
            getFarms: farmAction.FARM_GET_FARMS,
            getMultiPromos: multiFarmPromoAction.MULTI_FARM_FETCHPROMOS
        }),

        ...mapMutations({
            setCurrent: farmAction.FARM_SET_CURRENT,
            unsetCurrent: farmAction.FARM_UNSET_CURRENT,
            resetFilters: filterActions.FILTER_STORES_RESET,
            resetStores: storeActions.STORE_RESET_STORES,
            resetClusters: clusterActions.CLUSTER_RESET_CLUSTERS
        }),

        multiAction(event) {
            this.$router.push({name: "Multi", params: {lang: this.$i18n.locale}})

        },

        selectFarm(farm) {
            this.setCurrent(farm);
            this.$router
                .push({
                    name: "Farm",
                    params: { lang: this.$i18n.locale, id: farm.IDConsorzio }
                })
                .catch(() => {});
        }
    },

    beforeMount() {
        this.unsetCurrent();
        this.resetStores();
        this.resetFilters();
        this.resetClusters();
    },

    mounted() {
        this.getFarms().then(state => {});
        this.getMultiPromos().then(state => {});
    }
};
</script>

<style scoped>
.wrapper {
    height: calc(100vh - 65px);
}
.flex-item-bottom {
    position: absolute;
    right: 28px;
    bottom: 28px;
    /* bottom: -56px */
}
.farm-container {
    overflow-y: auto;
    height: calc(100vh - 130px);
    padding-bottom: 96px;
}
</style>
