<template>
    <v-app :style="{ background: background }" :class="scrollbarTheme">
        <AppToolbar ref="appToolbar"></AppToolbar>
        <!-- <AppNavigationbar></AppNavigationbar> -->
        <v-main>
            <!-- <audio src="https://localhost:5001/Audio/audiotrack?md5=2a4481880936ba37edc093c90a132bc0" type="audio/m4a" controls></audio>
            <img src="http://localhost:3333/multimedia/thumb/899d8bf336bd1b0213cd4cc1c1d7d359"/> -->
            <!-- <v-container fluid fill-height pa-0> -->
                <!-- <v-container fluid style="height:100%"> -->
                <!-- <v-row class="pa-0 ma-0" style="border: 10px solid pink; height: 100%;">
                    <v-col class="pa-0 ma-0" style="border: 10px solid green;"> -->
                <!-- <v-fade-transition> -->
                    <router-view />
                <!-- </v-fade-transition> -->
                <!-- </v-col>
                </v-row> -->
            <!-- </v-container> -->
        </v-main>
        <!-- <AppFooter></AppFooter> -->
        <!-- <AppPlayer></AppPlayer> -->
        <AppSnackbar></AppSnackbar>
    </v-app>
</template>
<script>
import { mapMutations, mapState } from "vuex";

export const AppToolbar = () => import("../../components/core/AppToolbar");
export const AppNavigationbar = () =>
    import("../../components/core/AppNavigationbar");
export const AppSnackbar = () => import("../../components/core/AppSnackbar");

// export const AppFooter = () => import('../../components/core/AppFooter')

import * as userActions from '../../store/actions/user'

export default {
    components: {
        AppSnackbar,
        AppToolbar,
        // AppNavigationbar
    },

    computed: {
        ...mapState({
            background: state => state.appModule.background
        }),

        scrollbarTheme() {
            return this.$vuetify.theme.dark ? "dark" : "light";
        }
    },

    watch: {
        background: newValue => {
            console.log("cambiato bk");
        }
    },

    methods: {
        ...mapMutations({
            checklogged: userActions.USER_READTOKEN
        })
    },

    mounted() {
        console.log(this.$vuetify.application.top);
        console.log("passo sempre da qui");
        this.checklogged()
    }
};
</script>

<style scoped>
.theme--light::-webkit-scrollbar {
    width: 15px;
}

.theme--light::-webkit-scrollbar-track {
    background: #e6e6e6;
    border-left: 1px solid #dadada;
}

.theme--light::-webkit-scrollbar-thumb {
    background: #b0b0b0;
    border: solid 3px #e6e6e6;
    border-radius: 7px;
}

.theme--light::-webkit-scrollbar-thumb:hover {
    background: black;
}

body::-webkit-scrollbar {
    width: 15px;
}

body::-webkit-scrollbar-track {
    background: #202020;
    border-left: 1px solid #2c2c2c;
}

body::-webkit-scrollbar-thumb {
    background: #3e3e3e;
    border: solid 3px #202020;
    border-radius: 7px;
}

body::-webkit-scrollbar-thumb:hover {
    background: white;
}
</style>
