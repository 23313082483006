export default class Cluster {
    constructor() {
        this.cluster = {
            IdConsorzio : 0,
            IdGruppo : 0,
            NomeGruppo : '',
            IdClienti : []

        }
    }

    setGruppo(data) {
        this.cluster = {
            IdConsorzio : data.IdConsorzio,
            IdGruppo : data.IdGruppo,
            NomeGruppo : data.NomeGruppo,
            IdClienti : data.IdClienti
        }
        return this.cluster
    }

    setConsorzio(idConsorzio) {
        this.cluster.IdConsorzio = idConsorzio;
        return this.cluster;
    }

    getEmptyCluster() {
        return this.cluster;
    }
}
