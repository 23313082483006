import { compareDate } from '../utils/utils'

export const isMobile = {
    computed: {
        isMobile() {
            // eslint-disable-next-line no-undef
            return this.$vuetify.breakpoint.name === "xs" ? true : false;
        }
    }
}

export const promoStateMessage = {
    methods: {
        promoState(promo) {

            const expired = compareDate(promo.FineValidita) === 1 || compareDate(promo.FinePubblicazione) === 1;
            const toStart = promo.State !== 1 /*compareDate(promo.InizioValidita) === -1;-*/
            const deleted = promo.Action === 1;
            const suspended = promo.Sospeso === true;

            if (expired && !deleted || suspended) {
                return "warning";
            }
            else if (deleted) {
                return "error";
            } else  if (toStart) {
                return "info";
            }
            return "success";
        },

        promoMessage(promo) {
            const expired = compareDate(promo.FineValidita) === 1 || compareDate(promo.FinePubblicazione) === 1;
            const toStart = /*compareDate(promo.InizioValidita) === -1 &&*/ promo.State !== 1;
            const deleted = promo.Action === 1;
            const suspended = promo.Sospeso === true;

            //console.log(new Date(promo.InizioValidita).toLocaleDateString('it-IT'), dt.toLocaleDateString('it-IT'))

            if (suspended) {
                return "Sospesa";
            }
            else if (deleted) {
                return "Cancellata";
            } else if (expired) {
                return "Scaduta";
            } else if (toStart) {
                return "Non Pubblicata";
            }
            return "Pubblicata";
        },
    }
}

export const getFarmIcon = {
    methods: {
        getFarmIcon(farmId) {
            switch (farmId) {
                case 4129:
                case 4132:
                    return require(`@/assets/alleanza.svg`);

                case 4118:
                    return require(`@/assets/amiatina.svg`);

                case 4119:
                    return require(`@/assets/casarsa.svg`);

                case 4120:
                    return require(`@/assets/altogarda.png`);

                case 4121:
                    return require(`@/assets/liguria.svg`);

                case 4122:
                    return require(`@/assets/lombardia.svg`);

                case 4123:
                    return require(`@/assets/reno.svg`);

                case 4124:
                    return require(`@/assets/famigliacooperativa.jpg`);

                case 4125:
                    return require(`@/assets/novacoop.svg`);

                case 4128:
                    return require(`@/assets/firenze.svg`);

                case 4127:
                case 4134:
                    return require(`@/assets/sait.png`);

                case 4126:
                case 4130:
                case 4133:
                    return require(`@/assets/tirreno.svg`);

                case 4131:
                    return require(`@/assets/centro.svg`);

                case 4135:
                    return require(`@/assets/larisorta.png`);

                default:
                    return require(`@/assets/lombardia.svg`);
            }
        }
    }
}
