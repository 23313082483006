<template>
    <v-row
        class="fill-height promo-container"
        justify="center"
        :align-content="!loaded ? 'center' : 'start'"
        style="overflow-y:auto;"
    >
        <v-progress-circular
            v-if="loading"
            color="primary"
            size="75"
            indeterminate
        ></v-progress-circular>

        <v-col
            v-for="promo in filteredPromo"
            :key="`${promo.IdComunicazioneGenerica}`"
            class="d-flex justify-center"
            xs="6"
            sm="6"
            md="4"
            lg="3"
            xl="2"
        >
            <PromoCard :promo="promo" @selected="selectPromo(promo)">
            </PromoCard>
        </v-col>
        <v-spacer v-if="loaded"></v-spacer>

        <v-alert type="error" v-if="error">
            {{ error }}
        </v-alert>
    </v-row>
</template>

<script>
export const PromoCard = () => import("../../components/core/PromoCard");

import { mapActions, mapState } from "vuex";
import * as storeActions from "../../store/actions/store";
import * as promoActions from "../../store/actions/storePromos";
import { compareDate } from "../../utils/utils";

export default {
    props: ["storeId"],

    data: () => ({}),

    components: {
        PromoCard
    },

    computed: {
        ...mapState({
            promos: state => state.storePromosModule.promos,
            loading: state => state.storePromosModule.loading,
            loaded: state => state.storePromosModule.loaded,
            error: state => state.storePromosModule.error,
            promoFilter: state => state.filterModule.promoFilter
        }),

        filteredPromo() {
            let dt;
            dt = new Date();
            dt.setHours(0, 0, 0, 0);
            return this.promos.filter(promo => {
                const expired = compareDate(promo.FineValidita) === 1 || compareDate(promo.FinePubblicazione) === 1;
                const toPublish = compareDate(promo.InizioValidita) === -1 || compareDate(promo.InizioPubblicazione) === -1;
                const deleted = promo.Action === 1;
                const suspended = promo.Sospeso === true;
                const active =
                    !expired &&
                    !toPublish &&
                    !deleted &&
                    !suspended &&
                    promo.action !== 1;

                const f1 = this.promoFilter.expired && expired;
                const f2 = this.promoFilter.deleted && deleted;
                const f3 = this.promoFilter.toPublish && toPublish;
                const f4 = this.promoFilter.suspended && suspended;
                const f5 = this.promoFilter.active && active;
                const f6 =
                    this.promoFilter.text.length === 0
                        ? true
                        : promo.Titolo.toLowerCase().indexOf(
                              this.promoFilter.text
                          ) !== -1;

                const f7 = this.promoFilter.fromEnabled
                    ? new Date(promo.InizioValidita).setHours(0, 0, 0, 0) >= new Date(this.promoFilter.from).setHours(0, 0, 0, 0)
                    : true;

                const f8 = this.promoFilter.toEnabled
                    ? new Date(promo.InizioValidita).setHours(0, 0, 0, 0) <= new Date(this.promoFilter.to).setHours(0, 0, 0, 0)
                    : true;

                return (f1 || f2 || f3 || f4 && !expired || f5) && f6 && f7 && f8;
            });
        }
    },

    methods: {
        ...mapActions({
            getPromos: promoActions.STOREPROMOS_GET_STORE_PROMOS,
            getStore: storeActions.STORE_GET_STORE
        }),

        selectPromo(promo) {
            console.log(promo);
            this.$router.push({
                name: "StorePromo",
                params: { promoId: promo.IdComunicazioneGenerica }
            }).catch(() => {});
        }
    },

    beforeMount() {
        console.log(this.storeId);
        this.getPromos(parseInt(this.storeId));
    },

    mounted() {}
};
</script>

<style scoped>
.promo-container {
    width: 100%;
    padding-bottom: 96px;
}
</style>
