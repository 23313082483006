<template>
    <div
        class="d-flex flex-column flex-grow-1 fill-height"
    >
        <router-view></router-view>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import * as farmAction from "../../store/actions/farm";
import * as multiFarmPromoAction from "../../store/actions/multiFarmPromo";

export default {
    data: () => ({
    }),

    methods: {
        ...mapActions({
            getFarms: farmAction.FARM_GET_FARMS,
            getMultiPromos: multiFarmPromoAction.MULTI_FARM_FETCHPROMOS
        }),
    },

    mounted() {
        // console.log('SONO QUI', this.promoFilter)
        this.getMultiPromos().then(state => {});
        this.getFarms().then(state => {});
    }
};
</script>
