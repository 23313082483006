export default class Comunicazione {
    constructor() {
        this.comunicazione = {
            Action: 0,
            State: 0,
            Approvata: true,
            Sospeso: false,
            Titolo: '',
            Descrizione: '',
            Type: -1,
            Priorita: 10,
            InizioPubblicazione: new Date(),
            FinePubblicazione: new Date(),
            InizioValidita: new Date(),
            FineValidita: new Date(),
            PuntiVendita: [],
            Clusters: [],
            PuntiVenditaEsclusi: [],
            PuntiVenditaInclusi: [],
            ImageName: '',
            ObjectIdImage: '',
            Md5Image: '',
            AudioName: '',
            ObjectIdAudio: '',
            Md5Audio: '',
            VideoName: '',
            ObjectIdVideo: '',
            Md5Video: '',
            AttachmentName: '',
            ObjectIdAttachment: '',
            Md5Attachment: '',
            imageFile: null,
            audioFile: null,
            videoFile: null,
            attachmentFile: null,
            audioFileUrl: null,
            videoFileUrl: null,
            FarmId: 0,
            Consorzi : [],
            FarmsId: []
        }
    }

    getEmptyComunicazione() {
        return this.comunicazione
    }

    setStore(storeId) {
        this.comunicazione.PuntiVenditaInclusi.push(storeId)
        return this.comunicazione
    }

    setStores(stores) {
        this.comunicazione.PuntiVenditaInclusi = [...stores]
        return this.comunicazione
    }

    setFarms(farmIds) {
        this.comunicazione.FarmsId = farmIds
        return this.comunicazione;
    }

    setFile(url, name, type) {
        if (type.indexOf('audio') !== -1) {
            this.comunicazione.audioFileUrl = url
            this.comunicazione.AudioName = name
        }
        else {
            this.comunicazione.videoFileUrl = url
            this.comunicazione.VideoName = name
        }
        return this.comunicazione
    }

    setFarm(farmId) {
        this.comunicazione.FarmId = farmId
        return this.comunicazione
    }

    setFromTo(from, to) {
        this.comunicazione.InizioValidita = new Date(from);
        this.comunicazione.FineValidita = new Date(to);
        this.comunicazione.InizioPubblicazione = this.comunicazione.InizioValidita;
        this.comunicazione.FinePubblicazione = this.comunicazione.FineValidita;
        return this.comunicazione;
    }
}
