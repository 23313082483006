<template>
    <div
        fill-height
        :style="{ height: viewHeight + 'px' }"
        v-resize="onResize"
        class="wrapper"
    >
        <v-card flat class="list-wrapper" :style="{ height: viewHeight + 'px' }">
            <component :is="loadAsyncComponent" :id="id" :scrollerHeight="viewHeight"></component>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    props: ["id"],

    data: () => ({
        viewHeight: 0,
        hidden: false
    }),

    components: {
        // FilterBar
    },

    computed: {
        ...mapState({
            getFarmListType: state => state.appModule.farmListType,
            getFilterBarHeight: state => state.appModule.filterBarHeight
        }),

        loadAsyncComponent() {
            switch (this.getFarmListType) {
                case 0:
                    return () => ({
                        component: import(
                            "../../components/farms/FarmPromosLoader"
                        ),
                        //   loading: AwesomeSpinner,
                        //   error: SadFaceComponent,
                        timeout: 1000
                    });

                case 1:
                    return () => ({
                        component: import(
                            "../../components/farms/FarmStoresLoader"
                        ),
                        //   loading: AwesomeSpinner,
                        //   error: SadFaceComponent,
                        timeout: 1000
                    });

                case 2:
                    return () => ({
                        component: import(
                            "../../components/farms/FarmClustersLoader"
                        ),
                        //   loading: AwesomeSpinner,
                        //   error: SadFaceComponent,
                        timeout: 1000
                    });

                case 3:
                    return () => ({
                        component: import(
                            "../../components/farms/FarmPromeFilesLoader"
                        ),
                        //   loading: AwesomeSpinner,
                        //   error: SadFaceComponent,
                        timeout: 1000
                    });
            }
            return null;
        }
    },

    methods: {

        onResize() {
            this.$nextTick(function() {
                const vw = Math.max(
                    document.documentElement.clientWidth || 0,
                    window.innerWidth || 0
                );
                const vh = Math.max(
                    document.documentElement.clientHeight || 0,
                    window.innerHeight || 0
                );
                this.viewHeight = vh - this.$vuetify.application.top - this.getFilterBarHeight;

                //console.log(this.$refs.filterBar.clientHeight)
                /*                 console.log(vw, vh);
                console.log(this.$vuetify); */

                console.log("sono l'altezza del top", this.$vuetify.application.top )
            });
        }
    },

    mounted() {
        // this.$nextTick(async function() {
        // });
    }
};
</script>

<style scoped>
    .wrapper {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
    }

    .list-wrapper {
        flex-grow: 1;
        flex-shrink: 0;
        /* padding: 16px 0 16px 0px; */
    }
</style>
