<template>
    <v-row
        class="fill-height"
        justify="center"
        :align-content="!loaded ? 'center' : 'start'"
        style="overflow-y:auto;"
    >
        <v-progress-circular
            v-if="loading"
            color="primary"
            size="75"
            indeterminate
        ></v-progress-circular>

        <PromoPage :promo="promo" :toApprove="toApprove" v-if="loaded"></PromoPage>

        <v-alert type="error" v-if="error">
            {{ error }}
        </v-alert>
    </v-row>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export const PromoPage = () => import("../../components/promo/PromoPage");
import * as promoActions from "../../store/actions/promo";

export default {
    props: {
        promoId: {
            type: [String, Object],
            required: true
        }
    },

    data: () => ({}),

    components: {
        PromoPage
    },

    computed: {
        ...mapState({
            loaded: state => state.promoModule.loaded,
            loading: state => state.promoModule.loading,
            error: state => state.promoModule.error,
            promo: state => state.promoModule.promo,
            toApprove: state => state.promoModule.toApprove
        })
    },

    methods: {
        ...mapActions({
            getPromo: promoActions.PROMO_FETCHPROMO
        }),
        ...mapMutations({
            reset: promoActions.PROMO_RESET
        })
    },

    beforeMount() {
        this.reset();

        console.log(this.$route);
        // console.log(this.loaded);

        const storeId = this.$route.params.storeId !== undefined ? this.$route.params.storeId : 0;
        // console.log(storeId)

        if (!this.loaded) {
            this.getPromo({
                farmId: this.$route.params.id,
                promoId: this.promoId,
                storeId
            }).then(state => {
                console.log(state);
            });
        }
    },

    mounted() {
        console.log("sono la promo", this.promoId);
    }
};
</script>

<style></style>
