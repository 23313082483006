<template>
    <v-card flat width="100%" v-resize="onResize" :style="{height: viewHeight + 'px'}">
        <v-row
            class="fill-height promo-container pa-8"
            justify="center"
            :align-content="!loaded ? 'center':'start'"
        >
            <v-progress-circular
                v-if="loading"
                color="primary"
                size="75"
                indeterminate
            ></v-progress-circular>

            <router-view v-if="loaded"></router-view>

            <v-alert type="error" v-if="error">
                {{ error }}
            </v-alert>
        </v-row>
    </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as storeActions from "../../store/actions/store";

export default {
    props: ["storeId"],

    data: () => ({
        loaded: false,
        loading: false,
        error: '',
        viewHeight: 0
    }),

    computed: {
        ...mapState({
            getFilterBarHeight: state => state.appModule.filterBarHeight
        })
    },

    methods: {
        ...mapActions({
            getStore:storeActions.STORE_GET_STORE,
        }),

        onResize() {
            this.$nextTick(function() {
                const vw = Math.max(
                    document.documentElement.clientWidth || 0,
                    window.innerWidth || 0
                );
                const vh = Math.max(
                    document.documentElement.clientHeight || 0,
                    window.innerHeight || 0
                );
                this.viewHeight = vh - this.$vuetify.application.top - this.getFilterBarHeight;

                //console.log(this.$refs.filterBar.clientHeight)
                /*                 console.log(vw, vh);
                console.log(this.$vuetify); */
            });
        }
    },

    beforeMount() {
        this.loading = true
        console.log("carico il componente della route", this.storeId);
        this.getStore(parseInt(this.storeId))
            .then(store => {
                this.loading = false
                this.loaded = true
            })
            .catch(err => {
                this.error = err
                this.loading = false
            })
    },

    mounted() {}
};
</script>

<style>
</style>
