<template>
    <div class="d-flex flex-column flex-grow-1 fill-height">
        <div class="filter-wrapper" v-if="showFilterBar">
            <FilterBar></FilterBar>
        </div>

        <v-row
            class="ma-2"
            v-if="loading"
            justify="center"
            align-content="center"
        >
            <v-progress-circular
                color="primary"
                size="75"
                indeterminate
            ></v-progress-circular>
        </v-row>

        <div class="d-flex flex-column flex-grown-1"
            v-if="loaded"
        >
            <!-- <div class="filter-wrapper" v-if="showFilterBar">
                <FilterBar></FilterBar>
            </div> -->
            <v-fade-transition>
                <router-view></router-view>
            </v-fade-transition>
        </div>

        <v-row
            v-if="error"
            justify="center"
            align-content="center"
        >
            <v-alert type="error">
                {{ error }}
            </v-alert>
        </v-row>

        <v-fab-transition>
            <v-btn
                v-show="loaded && showFilterBar && canAddPromos"
                color="pink"
                dark
                fab
                right
                bottom
                class="v-btn-add"
                @click="addAction"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>
    </div>
    <!-- <v-container fluid pa-0 class="d-flex flex-column" fill-height>
        <v-row
            class="ma-2"
            v-if="loading"
            justify="center"
            align-content="center"
        >
            <v-progress-circular
                color="primary"
                size="75"
                indeterminate
            ></v-progress-circular>
        </v-row>

        <v-row
            v-if="loaded"
            justify="center"
            align-content="start"
            style="width: 100%;"
        >
            <div class="filter-wrapper" v-if="showFilterBar">
                <FilterBar></FilterBar>
            </div>
            <v-fade-transition>
                <router-view></router-view>
            </v-fade-transition>
        </v-row>

        <v-row
            class="ma-2"
            v-if="error"
            justify="center"
            align-content="center"
        >
            <v-alert type="error">
                {{ error }}
            </v-alert>
        </v-row>
        <v-fab-transition>
            <v-btn
                v-show="loaded && showFilterBar && canAddPromos"
                color="pink"
                dark
                fab
                right
                bottom
                class="v-btn-add"
                @click="addPromo"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>
    </v-container> -->
</template>

<script>
import Comunicazione from "../../viewmodels/comunicazione.js";
import Cluster from '../../viewmodels/cluster.js'
import { mapActions, mapMutations, mapState } from "vuex";
import * as appActions from "../../store/actions/app";
import * as farmActions from "../../store/actions/farm";
import * as storeActions from "../../store/actions/store";
import * as farmPromosActions from "../../store/actions/farmPromos";
import * as storePromosActions from "../../store/actions/storePromos";
import * as filterActions from "../../store/actions/filter";
import * as promoActions from "../../store/actions/promo";
import * as clusterActions from "../../store/actions/cluster";

export const FilterBar = () => import("../../components/filters/FilterBar");

export default {
    props: ["id"],

    data: () => ({
        farmId: 0,
        farm: null,
        loading: false,
        loaded: false,
        error: null
    }),

    components: {
        FilterBar
    },

    computed: {
        ...mapState({
            getFarmListType: state => state.appModule.farmListType,
            canAddPromos: state => state.userModule.privileges.addPromo
        }),

        showFilterBar() {
            return this.$route.name === "Promo" ||
                this.$route.name === "StorePromo"
                ? false
                : true;
        }
    },

    methods: {
        ...mapActions({
            fetchFarm: farmActions.FARM_GET_FARM,
            createPromo: promoActions.PROMO_NEWPROMO,
            saveCluster: clusterActions.CLUSTER_SET_CLUSTER
        }),

        ...mapMutations({
            resetFilters: filterActions.FILTER_STORES_RESET,
            resetStores: storeActions.STORE_RESET_STORES,
            resetPromos: farmPromosActions.FARMPROMOS_RESET,
            resetScrollers: appActions.APP_RESETSCROLLERS,
            appendFarmsPromo: farmPromosActions.FARMPROMOS_APPEND,
            appendStorePromo: storePromosActions.STOREPROMOS_APPEND,
            resetTabs: appActions.APP_RESETTABS,
            insertCluster: clusterActions.CLUSTER_ADD_OR_MODIFY_CLUSTER
        }),

        goBack() {
            this.$router.go(-1);
        },

        addAction() {
            if (this.getFarmListType !== 2) {
                this.addPromo();
            }
            else {
                this.addCluster();
            }
        },

        addCluster() {
            console.log('aggiungo cluster', this.id)
            const cluster = new Cluster();
            let data = cluster.setConsorzio(parseInt(this.id));
            this.$formdialog
                .open({
                    type: "",
                    title: "Aggiungi Cluster",
                    data: data,
                    formName: "AddEditCluster",
                    fullScreen: true
                })
                .then(async response => {
                    this.$formdialog.showProgress(true);
                    const result = await this.saveCluster(response)
                    this.insertCluster(result);
                    console.log(result);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    this.$formdialog.showProgress(false);
                    this.$formdialog.destroy();
                });
        },

        addPromo() {
            const comm = new Comunicazione();
            let data = comm.setFarm(this.$route.params.id);
            if (this.$route.params.storeId !== undefined) {
                data = comm.setStore(this.$route.params.storeId);
            }
            // console.log(this.$route.params.storeId)
            this.$formdialog
                .open({
                    type: "",
                    title: "Aggiungi Comunicazione",
                    data: data,
                    formName: "AddPromo",
                    fullScreen: true
                })
                .then(async response => {
                    this.$formdialog.showProgress(true);
                    console.log(response);
                    const result = await this.createPromo({
                        farmId: this.farmId,
                        promo: response
                    });
                    this.appendFarmsPromo(result);
                    this.appendStorePromo(result);
                    console.log(result);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    this.$formdialog.showProgress(false);
                    this.$formdialog.destroy();
                });
        }
    },

    beforeMount() {
        console.log("SONO IL BEFORE MOUNTED DEL ROOT STORE");
        this.resetStores();
        this.resetPromos();
        this.resetFilters();
        this.resetScrollers();
        this.resetTabs();
        this.farmId = parseInt(this.id.toString());
    },

    mounted() {
        console.log("SONO IL MOUNTED DEL ROOT STORE");
        this.$nextTick(function() {
            this.loading = true;
            this.fetchFarm(this.farmId)
                .then(farm => {
                    this.farm = farm;
                    this.loading = false;
                    this.loaded = true;
                })
                .catch(err => {
                    this.error = err;
                });
        });
    }
};
</script>

<style scoped>
.filter-wrapper {
    width: 100%;
    padding: 16px;
    /* margin: 16px; */
    /* box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12); */
}

.v-btn-add {
    bottom: 0;
    position: absolute;
    right: 16px;
    margin: 0 0 16px 0;
}
</style>
